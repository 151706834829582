export default [
	{ text: 'javascript', value: 100 },
	{ text: 'react', value: 90 },
	{ text: 'front-end', value: 100 },
	{ text: 'ios', value: 150 },
	{ text: 'mobile', value: 80 },
	{ text: 'python', value: 100 },
	{ text: 'objective-c', value: 100 },
	{ text: 'node.js', value: 50 },
	{ text: 'swift', value: 85 },
	{ text: 'swiftui', value: 85 },
	{ text: 'c++', value: 70 },
	{ text: 'golang', value: 50 },
	{ text: 'flask', value: 40 },
	{ text: 'sql', value: 60 },
	{ text: 'html5', value: 80 },
	{ text: 'css', value: 60 },
	{ text: 'java', value: 50 },
	{ text: 'php', value: 40 },
	{ text: 'latex', value: 60 },
	{ text: 'wordpress', value: 40 },
	{ text: 'bootstrap', value: 40 },
	{ text: 'material-ui', value: 40 },
	{ text: 'gatsby', value: 30 },
	{ text: 'hexo', value: 30 },
	{ text: 'git', value: 70 },
	{ text: 'jenkins pipeline', value: 60 },
	{ text: 'xctest', value: 40 },
	{ text: 'mocha', value: 30 },
	{ text: 'sinon', value: 20 },
	{ text: 'chai', value: 25 },
	{ text: 'jasmine', value: 30 },
	{ text: 'video integration', value: 85 },
	{ text: 'ad tech', value: 80 },
	{ text: 'restful api', value: 70 },
	{ text: 'fullstack', value: 75 },
	{ text: 'xcode', value: 60 },
	{ text: 'vim', value: 30 },
	{ text: 'chrome extension api', value: 40 },
	{ text: 'database design', value: 35 },
	{ text: 'wireshark', value: 30 },
	{ text: 'responsive design', value: 50 },
	{ text: 'docker', value: 20 },
	{ text: 'apache', value: 40 },
	{ text: 'photography', value: 35 },
	{ text: 'piano', value: 40 },
	{ text: 'harry potter trivia', value: 30 },
	{ text: 'lisp', value: 25 },
	{ text: 'client support', value: 40 },
	{ text: 'centos web panel', value: 35 },
	{ text: 'linux', value: 65 },
	{ text: 'photoshop', value: 50 },
];